import ErrorPage from '@widesk/pages/ErrorPage';

function table(path: string, serviceName: string, listPageFolderName: string, title: string) {
	return [
		{
			handle: { crumb: () => 'Liste des ' + title.toLowerCase() },
			errorElement: <ErrorPage />,
			path: path,
			lazy: async () => {
				const Module = await import(`../../src/pages/services/${serviceName}/${listPageFolderName}/${listPageFolderName}Page.tsx`);
				return { Component: Module.default };
			},
		},
	];
}

function splitView(path: string, serviceName: string, pageFolderName: string, title: string) {
	return [
		{
			handle: { crumb: () => 'Liste des ' + title.toLowerCase() },
			errorElement: <ErrorPage />,
			path: path + '/split',
			lazy: async () => {
				const Module = await import(`../../src/pages/services/${serviceName}/${pageFolderName}/${pageFolderName}Page.tsx`);
				return { Component: Module.default };
			},
		},
	];
}

function dashboard(path: string, serviceName: string, pageFolderName: string, title: string) {
	return [
		{
			handle: { crumb: () => 'Fiche ' + title.toLowerCase() },
			errorElement: <ErrorPage />,
			path: path + '/:id',
			lazy: async () => {
				const Module = await import(`../../src/pages/services/${serviceName}/${pageFolderName}/${pageFolderName}Page.tsx`);
				return { Component: Module.default };
			},
		},
	];
}

export default { table, splitView, dashboard };
