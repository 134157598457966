import { AbstractResolvableModel } from '@mathquis/modelx-resolvables';
import { ModelClass }              from '@mathquis/modelx/lib/types/collection';
import _get                        from 'lodash/get';
import ucFirst                     from '@widesk/tools/ucFirst';

type Type = 'default' | 'plural' | 'a' | 'the' | 'this';
type ReturnCase = 'u' | 'l' | 'uf';

const MISSING_VALUE = '[MISSING TRANSLATION]';
const MISSING_KEY_VALUE = '[MISSING KEY]';

let localizedData = {};

export const setModelLocalizedData = (data: any) => localizedData = data;

export interface WithLocalizedLabel {
	localize: (type?: Type, returnCase?: ReturnCase) => string;
}

export function ApiModelLocalizedLabel<T extends ModelClass<AbstractResolvableModel>>(Base: T) {
	return class extends Base {
		public static serviceName: string;

		public static localize(type: Type = 'default', returnCase?: ReturnCase) {
			const key = this.serviceName + '.' + this.path;
			let value = _get(localizedData, `${key}.${type}`, MISSING_KEY_VALUE + this.path);

			// Si on ne trouve pas de valeur au pluriel dans les traductions
			if (type === 'plural' && value.includes(MISSING_KEY_VALUE)) {
				const defaultValue = _get(localizedData, `${key}.default`, '') as string;
				// On génère automatiquement le pluriel du mot par défaut
				if (!defaultValue.includes(MISSING_VALUE)) {
					value = autoPluralize(defaultValue);
				}
			}

			switch (returnCase) {
				case 'u':
					return value.toUpperCase();
				case 'l':
					return value.toLowerCase();
				case 'uf':
					return ucFirst(value);
				default:
					return value;
			}
		}

		public localize = (type: Type = 'default', returnCase?: ReturnCase) => (this.constructor as any).localize(type, returnCase) as string;
	};
}

const autoPluralize = (str: string) => {
	const arr = str.split(' ').map(s1 => {
		return s1.split('-').map(s => {
			if (s.endsWith('al')) {
				s = s.replace(/al$/g, 'aux');
			} else if (s.length >= 3 && !s.endsWith('s') && !s.endsWith('er') && !s.endsWith('x')) {
				s = s + 's';
			} else if (s === 'au') {
				s = 'aux';
			}

			return s;
		}).join('-');
	});
	return arr.join(' ');
};