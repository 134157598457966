import React                             from 'react';
import { Observer as MobxReactObserver } from 'mobx-react';

export type ObserverProps = {
	render: () => React.ReactNode;
};

const Observer = ({ render }: ObserverProps) => {
	return <MobxReactObserver render={render as () => never} />;
};

export default Observer;
