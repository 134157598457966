import { useAuth }        from '@widesk/hooks/useAuth';
import ViewPrimary        from '@widesk/components/ViewPrimary';
import { LogoutOutlined } from '@ant-design/icons';
import Tooltip            from '@widesk/components/Tooltip';

export default function LayoutHeaderLogout() {
	const { logout } = useAuth();

	return (
		<Tooltip title="Déconnexion">
			<div>
				<ViewPrimary heightF width={64} center onClick={() => logout()} separatorL separatorProps={{ bg: 'white' }}>
					<LogoutOutlined style={{ fontSize: 20 }} />
				</ViewPrimary>
			</div>
		</Tooltip>
	);
}
