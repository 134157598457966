import { IResolvableDecoratorOptions } from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { resolvable }                  from '@mathquis/modelx-resolvables';
import { AbstractResolvableModel }     from '@mathquis/modelx-resolvables';
import { Connector }                   from '@mathquis/modelx';
import ApiModel                        from '@widesk/models/ApiModel';

export class EmptyConnector extends Connector {
	public async list() {
		return { items: [], res: { data: {} } };
	}
}

export const emptyConnector = new EmptyConnector();

export class EmptyModel extends ApiModel {
	public static get connector() {
		return emptyConnector;
	}

	static get path(): string {
		return '';
	}
}

export default function (options: WithRequiredProperty<IResolvableDecoratorOptions, 'attributeName'>): (
	target: AbstractResolvableModel,
	key: string,
) => void {
	return function (target: AbstractResolvableModel, key: string) {
		const model = options.unresolved || EmptyModel;

		return resolvable(model, {
			...options,
			unresolved: model,
		})(target, key);
	};
}
