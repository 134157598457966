import { observable }       from 'mobx';
import { makeObservable }   from 'mobx';
import { computed }         from 'mobx';
import { action }           from 'mobx';
import { IObservableArray } from 'mobx';
import { AxiosResponse }    from 'axios';

export type Request = {
	startTime?: Date;
	endTime?: Date;
	response?: AxiosResponse;
	title?: string;
	duration?: number;
	startColor?: string;
}

class DevToolbarStore {
	@observable private _requests: Request[] = [];
	@observable private _consoleErrors: string[] = [];

	public readonly enabled;

	constructor() {
		const urlParams = new URLSearchParams(window.location.search);
		const isEnabledByUrl = urlParams.has('__DEV__'); // Permet d'afficher la toolbar en utilisant le paramètre __DEV__ dans l'url

		this.enabled = window.FRONT_CONFIG.devToolbarEnabled || __LOCAL__ || isEnabledByUrl;

		makeObservable(this);
	}

	@action addRequest(request: Request) {
		if (this.enabled) {
			this._requests.push({
				...request,
				startColor: dateToColour(request.startTime || new Date()),
				duration: ((request.endTime || 0) as number) - ((request.startTime || 0) as number),
			});
		}
	}

	@action addConsoleError(message: string): void {
		if (this.enabled) {
			this._consoleErrors.push(message);
		}
	}

	@computed get requests() {
		return this._requests;
	}

	@computed get consoleErrors() {
		return this._consoleErrors;
	}

	@action clearRequests() {
		setTimeout(action(() => (this._requests as IObservableArray).clear()));
	}

	@action clear() {
		this.clearRequests();
		setTimeout(action(() => (this._consoleErrors as IObservableArray).clear()));
	}
}

const dateToColour = (date: Date) => {
	const str = date.getSeconds() + '-' + date.getMilliseconds();

	let hash = 0;
	str.split('').forEach(char => {
		hash = char.charCodeAt(0) + ((hash << 5) - hash);
	});
	let colour = '#';
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff;
		colour += value.toString(16).padStart(2, '0');
	}
	return colour;
};

export default new DevToolbarStore();
