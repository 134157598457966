let errorsTranslations: Record<string, string> | undefined = undefined;

export const setErrorsTranslations = (translations?: Record<string, string>) => {
	errorsTranslations = translations;
};

export default function getTextAndTranslateFromError(err: any) {
	const errorCode: string = err?.connectorError?.request?.status
		? ` (code erreur ${err?.connectorError?.request?.status})`
		: '';

	const textError = (
		err?.connectorError?.response?.data?.['hydra:description']
		|| err?.connectorError?.response?.data?.message
		|| err?.connectorError?.response?.data?.header?.message
		|| err?.connectorError?.message
		|| err?.response?.data?.header?.message
		|| err?.response?.data?.['hydra:description']
		|| err.message
	);

	const textErrorTranslated = errorsTranslations ? (errorsTranslations[textError] || textError) : textError;

	let description: string | undefined = textErrorTranslated + errorCode;

	if (description === 'undefined') {
		description = undefined;
	}

	return description;
}
