import AntdBadge from 'antd/lib/badge';
import React     from 'react';

export type BadgeProps = {
	color: string;
	text: React.ReactNode;
}

const Badge = (props: BadgeProps) => {

	return (
		<AntdBadge
			color={props.color}
			text={props.text}
		/>
	);
};

export default Badge;
