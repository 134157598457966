import React                 from 'react';
import AntdResult            from 'antd/lib/result';

export type ResultProps = {
	children?: React.ReactNode;
	extra?: React.ReactNode;
	icon?: React.ReactNode;
	status?: 403 | 404 | 500 | "403" | "404" | "500" | "success" | "error" | "info" | "warning";
	style?: React.CSSProperties;
	subTitle?: React.ReactNode;
	title?: React.ReactNode;
}

const Result = (props: ResultProps) => {
	return (
		<AntdResult
			extra={props.extra}
			icon={props.icon}
			status={props.status}
			style={props.style}
			subTitle={props.subTitle}
			title={props.title}
		>
			{props.children}
		</AntdResult>
	);
};

export default Result;
