import Result                       from '@widesk/components/Result';
import Button                       from '@widesk/components/Button';
import { useNavigate }              from 'react-router-dom';
import getTextAndTranslateFromError from '@widesk/tools/getTextAndTranslateFromError';
import View                         from '@widesk/components/View';
import useTheme                     from '@widesk/hooks/useTheme';
import { useAuth }                  from '@widesk/hooks/useAuth';

export type ContentErrorProps = {
	error: any;
	retry?: () => void;
};

export default function ContentError(props: ContentErrorProps) {
	const navigate = useNavigate();
	const theme = useTheme();
	const { logout } = useAuth();

	const status = props.error.status;

	const logo = <img alt="logo" src="/favicon.svg" width={200} />;
	const home = <Button type="primary" onClick={() => navigate('/')}>Retour à la page d'accueil</Button>;
	const retry = props.retry ?
		<Button type="primary" onClick={() => props.retry!()}>Réessayer</Button> :

		<View gap={theme.marginLG} inline>
			<Button type="primary" onClick={() => location.reload()}>Recharger la page</Button>
			<Button type="link" onClick={() => logout()}>Déconnexion</Button>
		</View>
	;

	switch (status) {
		case 404:
			return <Result icon={logo} title={'Oops ! Page non trouvée. (404)'} extra={home} />;

		case 400:
			return <Result status={'error'} title={`Requête Invalide (400)`} extra={retry} />;

		case 403:
			return <Result status={403} title={`Accès Interdit (403)`} extra={home} />;

		default:
			return (
				<Result
					status={'error'}
					title={typeof props.error === 'string' ? props.error : 'Une erreur est survenue.'}
					subTitle={getTextAndTranslateFromError(props.error)}
					extra={retry}
				/>
			);
	}
}
