import AntdPopover          from 'antd/lib/popover';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React                from 'react';

export type PopoverProps = {
	children: React.ReactNode;
	title?: React.ReactNode;
	content?: React.ReactNode;
	trigger?: 'hover' | 'focus' | 'click';
	placement?: TooltipPlacement;
	open?: boolean;
	onOpenChange?: (newOpen: boolean) => void
}

export default function Popover(props: PopoverProps) {
	return (
		<AntdPopover
			trigger={props.trigger}
			title={props.title}
			content={props.content}
			placement={props.placement}
			open={props.open}
			onOpenChange={props.onOpenChange}
		>
			{props.children}
		</AntdPopover>
	);
}
