import resolvable    from '@widesk/decorators/resolvable';
import path          from '@widesk/decorators/path';
import urnResource   from '@widesk/decorators/urnResource';
import cacheDuration from '@widesk/decorators/cacheDuration';

export default {
	cacheDuration,
	resolvable,
	path,
	urnResource,
};
