export function flattenObject(obj: any, prefix = '', brackets = false) {
	return Object.keys(obj).reduce((acc: any, key) => {

		const prefixedKey = prefix ? (brackets ? `${prefix}[${key}]` : `${prefix}.${key}`) : key;

		if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof File)) {
			Object.assign(acc, flattenObject(obj[key], prefixedKey, brackets));
		} else {
			acc[prefixedKey] = obj[key];
		}
		return acc;
	}, {});
}