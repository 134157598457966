import '@widesk/styles/style.scss';
import '@/styles/style.scss';

import 'dayjs/locale/fr';

import AuthProvider              from '@widesk/contexts/AuthContext';
import ConfigProvider            from 'antd/lib/config-provider';
import LoginCheckModel           from '@/models/services/admin/LoginCheckModel';
import React                     from 'react';
import RouterProvider            from '@/RouterProvider';
import ThemeModeContext          from '@widesk/contexts/ThemeModeContext';
import { ThemeMode }             from '@widesk/contexts/ThemeModeContext';
import appStore                  from '@/stores/appStore';
import authStore                 from '@widesk/stores/authStore';
import dayjs                     from 'dayjs';
import errorsFr                  from '@/errorTranslations/fr.json';
import listFilterManagerStore    from '@widesk/stores/ListFilterManagerStore';
import locale                    from 'antd/locale/fr_FR';
import localizedFormat           from 'dayjs/plugin/localizedFormat';
import theme                     from 'antd/lib/theme';
import utc                       from 'dayjs/plugin/utc';
import { setErrorsTranslations } from '@widesk/tools/getTextAndTranslateFromError';
import ApiModel                  from '@widesk/models/ApiModel';
import LocaleCollection          from '@widesk/models/LocaleCollection';
import cacheSystem               from '@widesk/models/apiCacheSystem';
import axios                     from 'axios';

import { getIdFromUrn }          from '@widesk/tools/identifier';

dayjs.locale('fr');
dayjs.extend(localizedFormat);
dayjs.extend(utc);

setErrorsTranslations(errorsFr);

// Pour utiliser LocaleCollection par défault à la place de ApiCollection,
// Ce qui permettra aux traductions d'être fetch par défault pour les models compatibles (lang=getLocale())
ApiModel.getResolvableCollection = () => LocaleCollection;

const LOCALSTORAGE_THEME_KEY = 'theme_mode';

export default function App() {
	const [mode, setMode] = React.useState<ThemeMode>(
		localStorage.getItem(LOCALSTORAGE_THEME_KEY) as ThemeMode || 'light',
	);

	// On sauvegarde le mode en localStorage quand il change
	React.useEffect(() => localStorage.setItem(LOCALSTORAGE_THEME_KEY, mode), [mode]);

	return (
		<ThemeModeContext.Provider value={{ mode, setMode }}>
			<ConfigProvider
				locale={locale}
				theme={{
					algorithm: mode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
					token: { colorPrimary: '#E62E7A' },
				}}
			>
				<AuthProvider
					onLogin={async (username, password) => {
						const loginCheckModel = new LoginCheckModel();
						await loginCheckModel.patch({ password, username });
						return [loginCheckModel.token, loginCheckModel.refreshToken];
					}}
					onLogout={async () => {
						cacheSystem.clear();
						listFilterManagerStore.clear();
						appStore.user.clear();
					}}
					onLogged={async () => { // L'application charge tant que cette méthode n'est pas terminée
						
						authStore.setRefreshTokenRequest(async () => {
							const service = window.APP_CONFIG.services.find(service => service.id === 'admin');
							
							const response = await axios.post(service?.configurations.api_endpoint + '/token/refresh', {
								refresh_token: authStore.refreshToken,
							});

							return { token: response.data.token, refreshToken: response.data.refresh_token };
						});

						if (authStore.tokenData['owner.urn']) {

							const userId = getIdFromUrn(authStore.tokenData['owner.urn']);
							
							if (userId) {
								await appStore.user.setId(userId).fetch();
							}

						}
					}}
				>
					<RouterProvider />
				</AuthProvider>
			</ConfigProvider>
		</ThemeModeContext.Provider>
	);
}
