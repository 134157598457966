import React from 'react';

export type ThemeMode = 'dark' | 'light';

type ThemeModeContextType = {
	mode: ThemeMode;
	setMode: (value: ThemeMode) => void;
}

const ThemeModeContext = React.createContext<ThemeModeContextType>({
	mode: 'light',
	setMode: () => null,
});

export const ThemeModeContextProvider = (props: { children: React.ReactNode }) => {
	const [mode, setMode] = React.useState<ThemeMode>('dark');

	return (
		<ThemeModeContext.Provider value={{ mode, setMode }}>
			{props.children}
		</ThemeModeContext.Provider>
	);
};

export default ThemeModeContext;
