import ApiCollection from '@widesk/models/ApiCollection';
import ApiModel      from '@widesk/models/ApiModel';
import { getLocale } from '@widesk/locale';

/**
 * Ajoute un header à la requête pour fetcher les translations (avec la LanguageKey renvoyée par getLocale()).
 * Utilisable en instanciant une collection avec "new LocaleCollection(Model)"
 * ou en ajoutant la ligne "ApiModel.getResolvableCollection = () => LocaleCollection" au fichier App.tsx de l'application
 **/
export default class LocaleCollection<M extends ApiModel> extends ApiCollection<M> {
	public list(options?: ConnectorListOptions) {
		return super.list({ ...options, translations: getLocale() });
	}
}
