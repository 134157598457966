import { AbstractResolvableModel } from '@mathquis/modelx-resolvables';
import { ModelClass }              from '@mathquis/modelx/lib/types/collection';
import { navigate }                from '@widesk/hooks/useNavigate';

export function ApiModelGoToDashboard<T extends ModelClass<AbstractResolvableModel>>(Base: T) {
	return class extends Base {		

		public get pagePath() {
			return this.path;
		}

		public goToDashboard = () => !!this.id && navigate(this.pagePath);
		
	};
}
