import '@widesk/components/styles/Form.scss';

import AntdForm                     from 'antd/lib/form';
import { Rule }                     from 'antd/lib/form';
import ApiModel                     from '@widesk/models/ApiModel';
import Col                          from 'antd/lib/col';
import FormContext                  from '@widesk/contexts/FormContext';
import React                        from 'react';
import Row                          from 'antd/lib/row';
import Spin                         from '@widesk/components/Spin';
import View                         from '@widesk/components/View';
import _get                         from 'lodash/get';
import _upperFirst                  from 'lodash/upperFirst';
import useDidMount                  from '@widesk/hooks/useDidMount';
import useModal                     from '@widesk/hooks/useModal';
import useTheme                     from '@widesk/hooks/useTheme';
import useWizardForm                from '@widesk/hooks/useWizardForm';
import { FormItem }                 from '@widesk/components/form/FormItem';
import { message }                  from '@widesk/hooks/useMessage';
import Alert                        from '@widesk/components/Alert';
import getTextAndTranslateFromError from '@widesk/tools/getTextAndTranslateFromError';
import Button                       from '../Button';

export type FormFields = {
	[name: string]: FormField;
}

export type FormField = {
	forceLineBreak?: boolean; // Une nouvelle "Row" est ajoutée dans la grille à partir de ce champ
	hidden?: boolean | (() => boolean); // Si "true" le champ n'est pas monté
	disabled?: boolean;
	required?: boolean;
	span?: ColSize | (() => ColSize); // Permet de définir la largeur de la colonne dans la grille
	noFormItem?: boolean; // Les champs ne seront plus wrap dans un composant FormItem
	element: React.ReactNode;
	initialValue?: unknown;
	rules?: Rule[];
} & ({
	noFormItem: true; // Quand "noFormItem" est true, on ne renseigne pas de label
	label?: undefined;
} | {
	noFormItem?: false;
	label?: string;
});

export type FormProps<T extends FormFields> = {
	fields?: T | (() => T);
	noGrid?: boolean; // Permet de désactiver la grille ; les champs sont affichés côte à côtes
	onSubmit?: (values: { [Property in keyof T]: unknown }, tools: FormTools<T>) => Promise<any>;
	onSuccess?: (values: { [Property in keyof T]: unknown }, tools: FormTools<T>) => void;

	// Méthode appelée au chargement. Le contenu n'est pas monté tant que la promesse n'est pas résolue
	onDidMount?: (formTools: FormTools<T>) => Promise<void>;

	onChange?: (formTools: FormTools<T>) => void;

	children?: React.ReactNode;
	loading?: boolean;
	withButton?: boolean;
}

export type FormTools<T extends FormFields> = {
	getValues: () => Record<keyof T, string>;
	getFieldValue: (name: keyof T) => string;
	setFieldValue: (name: keyof T | string[], value: unknown) => void;
	setFieldsValue: (values: Partial<Record<keyof T, unknown>>) => void;
	setSubmitting: (value: boolean) => void;
	setDisabled: (value: boolean) => void;
	resetFields: (name?: (keyof T | string[])[]) => void;
}

export default function Form<T extends FormFields>(props: FormProps<T>) {
	const {
		children,
		noGrid,
		onDidMount,
		onChange,
		onSubmit,
	} = props;

	const fields = typeof props.fields === 'function'
		? props.fields()
		: props.fields || {} as T;

	const [form] = AntdForm.useForm();
	const { wizardFormStore } = useWizardForm();
	const { modalStore } = useModal();
	const theme = useTheme();

	const [loading, setLoading] = React.useState(!!onDidMount);
	const [errors, setErrors] = React.useState<string[]>([]);

	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

	const tools: FormTools<T> = {
		getValues: () => form.getFieldsValue(),
		getFieldValue: (name) => form.getFieldValue(name),
		setFieldValue: (name, value) => form.setFieldValue(name, value),
		setFieldsValue: (values) => form.setFieldsValue(values),
		setSubmitting: (value: boolean) => modalStore?.setIsSubmitting(value),
		setDisabled: (value: boolean) => modalStore?.setIsDisabled(value),
		resetFields: () => form.resetFields(),
	};

	const columnNamesByRow = Object.entries(fields).reduce<string[][]>((acc, [name, field]) => {
		if (field.forceLineBreak) {
			acc.push([]);
		}

		acc[acc.length - 1]?.push(name);

		return acc;
	}, [[]]);

	const onFinish = async (values: Record<keyof T, unknown>) => {
		try {
			tools.setSubmitting(true);
			setIsSubmitting(true);

			const result = onSubmit ? await onSubmit(values, tools) : undefined;

			// On ne passe pas à la suite si la méthode "onSubmit" retourne "false"
			if (result === false) {
				return false;
			}

			if (result instanceof ApiModel) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const success = _upperFirst(`L'élément ${result.localize()} a bien été enregistré.`);
				message.success(success);
			}

			if (props.onSuccess) {
				props.onSuccess(values, tools);
			}

			// Si le formulaire est dans une modale ET qu'il n'est pas dans un wizard
			if (modalStore && !wizardFormStore) {
				modalStore.setIsOpen(false); // On ferme la modale
			}

			setErrors([]);

			// On retourne les values (elles sont utilisées dans le WizardForm)
			return values;
		} catch (e) {
			const violations: any[] = _get(e, 'connectorError.response.data.violations', []);
			const fields = form.getFieldsValue();

			// Permet d'afficher le message d'erreur de l'api sous le champ qui correspond
			form.setFields(
				violations
					.map(v => {
						const vN = v.propertyPath;
						const name = [vN, `${vN}Urn`, `${vN}Urns`].find(n => Object.prototype.hasOwnProperty.call(fields, n));
						return { errors: [v.message], name };
					})
					.filter(v => v.name),
			);

			const textFromError = getTextAndTranslateFromError(e);
			if (textFromError) {
				setErrors([textFromError]);
			}

			throw e;
		} finally {
			tools.setSubmitting(false);
			setIsSubmitting(false);
		}
	};

	useDidMount(async () => {
		// On assigne la méthode submit à l'action "submit" de la modale
		modalStore?.setSubmitCallback(() => form.submit());

		if (onDidMount) {
			try {
				await onDidMount(tools);

				setLoading(false);

				if (onChange) {
					onChange(tools);
				}
			} catch (err) {
				console.error(err);
				throw err;
			}
		} else if (onChange) {
			onChange(tools);
		}

		// Si on est dans un wizardForm, on utilise les valeurs enregistrées dans la store wizard
		if (wizardFormStore && wizardFormStore.hasValues) {
			tools.setFieldsValue(wizardFormStore.values);
		}
	});

	useDidMount(() => {
		// Méthode qui va être utilisée par le WizardForm pour soumettre le formulaire
		wizardFormStore?.setDefaultSubmit(async () => {
			await form.validateFields();
			return onFinish(form.getFieldsValue());
		});
		return () => wizardFormStore?.setDefaultSubmit(undefined);
	});

	const CustomRow = noGrid ? NoWrapper : Row;
	const CustomCol = noGrid ? NoWrapper : Col;

	return (
		<FormContext.Provider value={{ fields, tools }}>
			<View gap={theme.marginLG}>
				{!!errors.length && <Alert type="error" showIcon message={errors} />}

				<AntdForm.Provider onFormChange={() => {
					if (onChange) {
						onChange(tools);
					}
				}}>
					<AntdForm
						className="widesk-form"
						labelCol={{ span: 24 }}
						form={form}
						onFinish={onFinish}
						id={modalStore?.props.okButtonProps?.form}
					>
						{(loading || isSubmitting || props.loading) ? (
							<Spin paddingV={theme.marginLG} size="large" tip="Chargement du formulaire" />
						) : (
							<View gap={theme.marginLG}>
								{columnNamesByRow.map((names, rowIdx) => {
									/** Permet de déterminer si tous les Cols de la Row sont "hidden" */
									const allHidden = !names.some(name => {
										const field = fields[name]!;
										if (typeof field.hidden === 'function') {
											return !field.hidden();
										}
										return !field.hidden;
									});

									if (allHidden) {
										return null;
									}

									return (
										<CustomRow key={rowIdx} gutter={[theme.marginLG, theme.marginLG]}>
											{names.map((name, colIdx) => {
												const key = colIdx + name;
												const field = fields[name]!;
												const span = typeof field.span === 'function' ? field.span() : (field.span || 12);

												if (typeof field.hidden === 'function' && field.hidden()) {
													return null;
												} else if (field.hidden) {
													return null;
												}

												return (
													<CustomCol key={key} span={span}>
														{field.noFormItem ? field.element : (
															<FormItem
																required={field.required}
																label={field.label}
																name={name}
																initialValue={field.initialValue}
																rules={field.rules}
															>
																{field.element as any}
															</FormItem>
														)}
													</CustomCol>
												);
											})}
										</CustomRow>
									);
								})}

								{children}

								{props.withButton && (
									<Button size="large" block type="primary" htmlType="submit">
										Valider
									</Button>
								)}
							</View>
						)}
					</AntdForm>
				</AntdForm.Provider>
			</View>
		</FormContext.Provider>
	);
}

const NoWrapper = ({ children }: { children: React.ReactNode }) => <>{children}</>;

Form.Item = FormItem;
