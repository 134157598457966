import { useNavigate as RRDUseNavigate } from 'react-router-dom';
import { NavigateFunction }              from 'react-router-dom';

let _navigate: NavigateFunction;

export default function useNavigate() {
	_navigate = RRDUseNavigate();
	return _navigate;
}

export const navigate = (path: string) => {
	if (_navigate) return _navigate(path);
	console.error(`L'instance de navigate n'a pas encore été initialisée.`);
};
