import ApiModel                      from '@widesk/models/ApiModel';
import React                         from 'react';
import { HTMLAttributeAnchorTarget } from 'react';
import Value                         from '@widesk/components/Value';
import _get                          from 'lodash/get';
import _startCase                    from 'lodash/startCase';
import { observer }                  from 'mobx-react';
import useDidMount                   from '@widesk/hooks/useDidMount';
import Tag                           from '@widesk/components/Tag';
import { AbstractResolvableModel }   from '@mathquis/modelx-resolvables';

export type ModelNameProps<T extends AbstractResolvableModel> = {
	link?: boolean | string;
	linkTarget?: HTMLAttributeAnchorTarget;
	model: T;
}

export type CustomModelNameProps<T extends ApiModel> = ModelNameProps<T> & {
	link?: string;
}

const modules = import.meta.glob(['../../src/modelNames/*/*']);
const loadedModules: Record<string, any> = {}; // Permet de ne pas essayer de charger un même module plusieurs fois

export default function ModelName(props: ModelNameProps<ApiModel>) {
	const { model } = props;

	const { service, resource } = model.urnData || {};
	const fileName = _startCase(resource).split(' ').join('') + 'Name';

	const moduleKey = `../../src/modelNames/${service}/${fileName}.tsx`;

	const [module, setModule] = React.useState<any>(loadedModules[moduleKey]);

	useDidMount(async () => {
		if (typeof module === 'undefined' && resource) { // Si le module n'a pas déjà été chargé

			// On cherche si un composant existe pour le model
			const promise = modules[`../../src/modelNames/${service}/${fileName}.tsx`];

			if (promise) {
				const module = await promise(); // Import dynamique du composant
				setModule(module);
				loadedModules[moduleKey] = module;
			} else {
				setModule(false); // Utilisation du composant par défaut
				loadedModules[moduleKey] = false;
			}
		}
	});

	if (typeof module === 'undefined') {
		return <Value loading={true} value="" />;
	}

	if (module) {
		return <CustomModelName Component={module.default} modelNameProps={props} />;
	}

	return <DefaultModelName {...props} />;
}

const DefaultModelName = observer((props: ModelNameProps<ApiModel>) => {
	const { link, linkTarget } = props;

	const model = props.model;

	const color = _get(model, 'color') as string | undefined;
	const value = color ? <Tag color={color}>{model.modelLabel}</Tag> : model.modelLabel;

	let loading = !model.isLoaded;

	// S'il y a un model parent et qu'il est chargé mais que le model enfant n'a pas d'id => On arrête le chargement
	if (model.calledBy?.isLoaded && !model.id) {
		// Sauf si le model enfant est un reverseResolvable et qu'il n'est pas encore chargé
		if (model.isReverseResolvable) {
			if (model.isLoaded) {
				loading = false;
			}
		} else {
			loading = false;
		}
	}

	return (
		<Value
			loading={loading}
			link={typeof link === 'string' ? link : (link ? model.pagePath : undefined)}
			linkTarget={link ? linkTarget : undefined}
			value={value}
		/>
	);
});

/**
 * Permet d'observer le model sur les composants "name" custom
 */
export const CustomModelName = observer((props: { Component: any; modelNameProps: ModelNameProps<ApiModel> }) => {
	const { model, link, linkTarget } = props.modelNameProps;

	const modelNameProps: CustomModelNameProps<ApiModel> = {
		...props.modelNameProps,
		model: model,
		link: typeof link === 'string' ? link : (link ? model.pagePath : undefined),
		linkTarget: link ? linkTarget : undefined,
	};

	return <props.Component {...modelNameProps} />;
});
