import Menu           from 'antd/lib/menu';
import React          from 'react';
import { Link }       from 'react-router-dom';
import { useMatches } from 'react-router-dom';

export type NavigationItem = {
	label: React.ReactNode;
	icon?: React.ReactNode;
	link?: string;
	children?: NavigationItem[],
}

const getMenuItem = (item: NavigationItem) => {
	// Nous souhaitons avoir '/url/...' ceci nous permet d'avoir la couleur du background du menu actif.
	// Exemple: si item.link = 'mails/split' alors nous retournons '/mails/split'
	const link = !item.link?.startsWith('/', 0) ? `/${item.link}` : item.link;

	return {
		key: link,
		...item,
		label: item.link ? <Link to={item.link}>{item.label}</Link> : item.label,
		children: item.children ? getToMenuItems(item.children) : undefined,
	};
};

const getToMenuItems = (items: NavigationItem[]): any => {
	return items.map((item) => getMenuItem(item));
};

export type NavigationProps = {
	items: NavigationItem[];
	style?: React.CSSProperties;
}

export default function Navigation(props: NavigationProps) {
	const matches = useMatches();

	const selectedKeys = matches.length ? [matches.at(-1)?.pathname || ''] : undefined;

	return (
		<Menu
			style={props.style}
			theme="dark"
			selectedKeys={selectedKeys}
			mode="inline"
			items={getToMenuItems(props.items)}
			
		/>
	);
}
