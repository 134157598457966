import AntdInput from 'antd/lib/input';

export type InputProps = FieldProps & {
	value?: string;
	onChange?: () => void;
	autoFocus?: boolean;
	placeholder?: string;
}

export default function InputPassword(props: InputProps) {
	return (
		<AntdInput.Password
			placeholder={props.placeholder}
			autoFocus={props.autoFocus}
			disabled={props.disabled}
			onChange={props.onChange}
			size={props.size}
			value={props.value}
			style={props.style}
			id={props.id ? `input-password-${props.id}` : undefined}
		/>
	);
}
