import AdminUserStatusModel from '@models/admin/AdminUserStatusModel';
import doc                  from '@widesk/decorators/doc';
import model                from '@widesk/decorators/model';
import AdminServiceModel    from '@/models/AdminServiceModel';
import Blamable             from '@/models/traits/Blamable';
import TimestampAble        from '@widesk/models/traits/TimestampAble';

@model.urnResource('admin_user')
@doc.path('/admin_users/{?id}')
export default class AdminUserModel extends Blamable(TimestampAble(AdminServiceModel)) {
	private declare _tsFilters: {
		'adminUserStatus': id;
		'adminUserStatus.reference': Admin_AdminUserStatusReference;
	};

	private declare _tsSorts: {
		'firstname': string;
		'id': string;
		'lastname': string;
	};	

	@doc.model(AdminUserStatusModel) declare adminUserStatus: AdminUserStatusModel;
	@doc.string declare email: string;
	@doc.string declare firstname: string;
	@doc.string declare lastname: string;
	@doc.string declare password: string;
	@doc.string declare plainPassword: string;

	public get fullName() {
		return [this.firstname, this.lastname].join(' ');
	}
}