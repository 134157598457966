import _                                from 'lodash';
import { AbstractResolvableCollection } from '@mathquis/modelx-resolvables';
import ConnectorResults                 from '@mathquis/modelx/lib/types/connectorResults';
import { ToResolveDictionary }          from '@widesk/mixins/ApiModelWhenIsLoaded';
import ApiModel                         from '@widesk/models/ApiModel';
import utc                              from 'dayjs/plugin/utc';
import dayjs                            from 'dayjs';
import { computed }                     from 'mobx';
import { when }                         from 'mobx';
import { override }                     from 'mobx';
import { action }                       from 'mobx';
import { ICollectionOptions }           from '@mathquis/modelx/lib/types/collection';

dayjs.extend(utc);

export type CollectionSorts = Record<string, 'asc' | 'desc'>;
declare type iteratee<T, V> = (model: T, index?: number) => V;

const MAX_RESULTS = 1000; // Permet de déclencher une erreur si la collection contient trop de données

export default class ApiCollection<M extends ApiModel> extends AbstractResolvableCollection<M> {
	protected _filters: Partial<ModelFilters<M>> = {};
	protected _requiredFilters: ModelFilterName<M>[] = [];
	protected _sorts: CollectionSorts = {};

	protected initialize(options: ICollectionOptions) {
		super.initialize(options);

		// Si une collection est initialisée avec des models qui sont tous "isLoaded" on passe la collection en "isLoaded"
		if (!this.isLoaded && !this.isLoading && this.length && this.every(m => m.isLoaded)) this.setIsLoaded(true);
	}

	protected checkRequiredFilter(value: any) {
		return value !== undefined && value !== null && value !== '';
	}

	protected checkRequiredFilters() {
		for (const filter of this._requiredFilters) {
			if (
				!this.checkRequiredFilter(this._filters[filter])
				|| (
					typeof this._filters[filter] === 'object'
					&& !Object.values(this._filters[filter] || {}).some(this.checkRequiredFilter)
				)
			) {
				console.warn(`Filter "${filter}" empty for request "${this.path}"`);

				return false;
			}
		}

		return true;
	}

	@computed get ids(): id[] {
		return this.map(m => m.id);
	}

	@computed get urns(): Urn[] {
		return this.map(m => m.urn);
	}

	@computed get filters(): Partial<ModelFilters<M>> {
		return this._filters;
	}

	@computed get search(): ModelFilters<M>['search'] | undefined {
		return this.filters.search;
	}

	public map<V>(iteratee: iteratee<M, V>): V[] {
		return super.map(iteratee);
	}

	public clear() {
		this.setIsLoaded(false);
		this.clearFilters().clearSorts();
		return super.clear();
	}

	public clearFilters() {
		this._filters = {};
		return this;
	}

	public clearModels() {
		return super.clear();
	}

	public clearSorts() {
		this._sorts = {};
		return this;
	}

	public setRequiredFilter<FilterName extends ModelFilterName<M>>(name: FilterName, value: ModelFilters<M>[FilterName]) {
		this._requiredFilters.push(name);
		return this.setFilter(name, value);
	}

	public setFilter<FilterName extends ModelFilterName<M>>(name: FilterName, value?: ModelFilters<M>[FilterName]) {
		const DATE_FORMAT = 'YYYY-MM-DD[T]LTS';

		if (typeof value === 'undefined') {
			delete this._filters[name];
		} else if (value instanceof dayjs) {
			this._filters[name] = (value as unknown as Dayjs).utc().format(DATE_FORMAT) as never;
		} else if (Array.isArray(value)) {
			const arr = value as unknown[];

			arr.forEach((v, idx) => {
				if (v instanceof dayjs) {
					arr[idx] = (v as unknown as Dayjs).utc().format(DATE_FORMAT);
				}
			});

			// On supprime les éléments en doublon
			this._filters[name] = _.uniq(arr) as never;
		} else {
			this._filters[name] = value;
		}

		return this;
	}

	public addFilters(filters: ModelFilters<M>): this {
		Object.keys(filters || {}).forEach((name: any) => this.setFilter(name, (filters as any)[name]));
		return this;
	}

	public setFilters(filters: ModelFilters<M>): this {
		this._filters = {}; // Suppression de tous les filtres
		this.addFilters(filters);
		return this;
	}

	public setSort(name?: keyof ModelSorts<M>, way: SortWay = 'asc'): this {
		const key = `order[${name as string}]`;

		if (typeof name === 'undefined') {
			delete this._sorts[key];
		} else {
			this._sorts[key] = way;
		}

		return this;
	}

	@action setIsLoaded(value = true) {
		this.isLoaded = value;

		return this;
	}

	@action setIsLoading(value = true) {
		this['_pendingRequestCount'] = value ? 1 : 0;

		return this;
	}

	public uniqueId = _.uniqueId();
	public toResolveDictionary: ToResolveDictionary = {};

	public async listBy<FilterName extends ModelFilterName<M>>(
		values: string | string[],
		filterName = 'id' as FilterName,
		options: ConnectorListOptions = {},
	) {
		const arrValues = Array.isArray(values) ? values : [values];

		// On supprime les valeurs "undefined"
		let cleanValues = [...new Set(arrValues as never)].filter(v => typeof v !== 'undefined' && v !== '');

		if (filterName === 'id') {
			// On supprime les ids "0"
			cleanValues = cleanValues.filter(id => id);
		}

		this.setFilter(filterName, cleanValues as unknown as ModelFilters<M>[FilterName]);

		if (cleanValues.length) {
			await this.list(options);
		} else {
			this.clearModels();
		}

		return this;
	}

	// Pour les resolvables
	public async listById(identifiers: id[]): Promise<this> {
		await this.listBy(identifiers, 'id', { params: { itemsPerPage: identifiers.length } });

		// Si un id n'a pas été trouvé dans la requête list
		if (__LOCAL__) {
			const notFoundIdentifiers = identifiers.filter(id => !this.ids.includes(id));

			if (notFoundIdentifiers.length) {
				// On affiche un message avec la liste des entités et identifiants non trouvés
				const notFoundMessage = `Resolvable - listById - ${this.model.name} not found (ID : ${notFoundIdentifiers.join(', ')})`;
				console.error(notFoundMessage, this.ids);
			}
		}

		return this;
	}

	private _abortController?: AbortController;

	protected prepareListOptions(options: any) {
		this._abortController = new AbortController();

		const superOptions = {
			signal: this._abortController.signal,
			...options,
			params: { ...this._filters, ...this._sorts, ...options.params },
		};

		// Lorsqu'on filtre sur l'id on supprime la pagination
		if (superOptions.params.id) {
			const ids = Array.isArray(superOptions.params.id) ? superOptions.params.id : [superOptions.params.id];
			const itemsPerPage = superOptions.params.itemsPerPage || -1;

			if (ids.length <= itemsPerPage) { // Seulement s'il y a moins d'IDs en paramètre que la pagination en cours
				superOptions.params.itemsPerPage = ids.length;
				superOptions.params.page = undefined;
				superOptions.params.partial = true; // Évite une requête COUNT du total sur l'API
			}
		}

		return super.prepareListOptions(superOptions);
	}

	/**
	 * La collection doit déjà être "isLoaded" lorsqu'on appelle cette fonction
	 */
	public async whenIsLoaded(iterator?: (m: M) => ApiModel | ApiModel[]) {
		if (!iterator) {
			return when(() => this.isLoaded);
		}

		const toLoadModels = this.map(model => iterator(model)).flat();
		await Promise.all(toLoadModels.map(toLoadModel => toLoadModel.whenIsLoaded()));
	}

	@override onListSuccess(results: ConnectorResults, options: object) {
		super.onListSuccess(results, options);

		const typedOptions = options as ConnectorListOptions;
		const maxResults = typedOptions.maxResults || MAX_RESULTS;

		if (this.length > maxResults) {
			const message = `La collection "${this.path}" contient plus de ${maxResults} résultats. (${this.length} résultats)`;
			console.error(message);
			throw Error(message);
		}
	}

	public abort() { // Permet d'interrompre une requête en cours
		this._abortController?.abort();
	}

	public async list(options?: ConnectorListOptions) {
		if (this.checkRequiredFilters()) {
			return super.list(options);
		}

		return this;
	}

	public distinctBy<V>(iteratee: iteratee<M, V>): M[] {
		return _.uniqBy(this.models, iteratee);
	}

	public groupBy<V>(iteratee: iteratee<M, V>): Record<string, M[]> {
		return _.groupBy(this.models, iteratee);
	}
}
