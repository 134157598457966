import { observable }     from 'mobx';
import { makeObservable } from 'mobx';
import { action }         from 'mobx';
import { computed }       from 'mobx';
import React              from 'react';

class BreadcrumbBarStore {
	@observable private _enabled = true;

	@observable private _rightComponent?: () => React.ReactNode;

	constructor() {
		makeObservable(this);
	}

	@action setRightComponent(component?: () => React.ReactNode) {
		this._rightComponent = component;
	}

	@action setEnabled(value: boolean) {
		this._enabled = value;
	}

	@computed get rightComponent() {
		return this._rightComponent;
	}

	@computed get enabled() {
		return this._enabled;
	}
}

export default new BreadcrumbBarStore();
