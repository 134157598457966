import { BookOutlined }                     from '@ant-design/icons';
import { HomeOutlined }                     from '@ant-design/icons';
import { TeamOutlined }                     from '@ant-design/icons';
import { UserOutlined }                     from '@ant-design/icons';
import AdminUserModel                       from './models/services/admin/AdminUserModel';
import SubscriptionModel                    from './models/services/account/SubscriptionModel';
import AccountModel                         from './models/services/account/AccountModel';

export default [
	{ label: 'Accueil', icon: <HomeOutlined />, link: '/' },
	{
		label: AccountModel.localize('plural'),
		link: AccountModel.pagePath('list'),
		icon: <TeamOutlined />,
	},
	{
		label: AdminUserModel.localize('plural'), 
		link: AdminUserModel.pagePath('list'),
		icon: <UserOutlined />,
	},
	{
		label: SubscriptionModel.localize('plural'), 
		link: SubscriptionModel.pagePath('list'),
		icon: <BookOutlined />,
	},
];

export const navigationSettings = [];
