import ApiModel           from '@widesk/models/ApiModel';
import ModelName          from '@widesk/components/ModelName';
import React              from 'react';
import { ModelClass }     from '@mathquis/modelx/lib/types/collection';
import { ModelNameProps } from '@widesk/components/ModelName';

export type RenderNameProps<M extends ApiModel> = Omit<ModelNameProps<M>, 'model'>;

export interface WithRenderName {
	renderName(props?: RenderNameProps<any>): React.ReactNode;
}

export function ApiModelRenderName<T extends ModelClass<ApiModel>>(Base: T) {
	return class extends Base {
		public renderName(props?: RenderNameProps<this>) {
			return React.createElement(ModelName, { ...props, model: this as any });
		}
	};
}