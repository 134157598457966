import '@widesk/styles/AuthLayout.scss';

import AntdLayout        from 'antd/lib/layout';
import DevToolbar        from '@widesk/components/DevToolbar';
import EnvHeadband       from '@widesk/components/EnvHeadband';
import Page              from '@widesk/components/Page';
import React             from 'react';
import View              from '@widesk/components/View';
import { ModalProvider } from '@widesk/components/modal/ModalProvider';
import { Outlet }        from 'react-router-dom';
import useDidMount       from '@widesk/hooks/useDidMount';
import useMessage        from '@widesk/hooks/useMessage';
import useNavigate       from '@widesk/hooks/useNavigate';
import useTheme          from '@widesk/hooks/useTheme';

export type AuthLayoutProps = {
	renderHeader?: () => React.ReactNode;
	renderSider?: () => React.ReactNode;
	renderTopPage?: () => React.ReactNode;
	onDidMount?: () => void;
	siderWidth?: number;
	initialCollapsed?: boolean;
}

export default function AuthLayout(props: AuthLayoutProps) {
	useNavigate();

	const theme = useTheme();
	const [contextHolder] = useMessage();

	const [collapsed, setCollapsed] = React.useState(!!props.initialCollapsed);
	const [isReady, setIsReady] = React.useState(false);

	const siderWidth = props.siderWidth || 300;

	// On attend que le composant soit monté pour l'afficher afin d'éviter une dislocation
	useDidMount(() => setIsReady(true));

	useDidMount(() => props.onDidMount ? props.onDidMount() : undefined);

	return (
		<View height="100vh" bg={theme.colorBgContainer} color={theme.colorTextBase}>
			{contextHolder}

			<AntdLayout className="widesk-auth-layout" style={{ height: '100%', ...(isReady ? {} : { visibility: 'hidden' }) }}>
				{!!props.renderSider && (
					<AntdLayout.Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)} width={siderWidth}>
						{props.renderSider()}
					</AntdLayout.Sider>
				)}

				<AntdLayout>
					{!!props.renderHeader && (
						<AntdLayout.Header style={{ padding: 0 }}>
							{props.renderHeader()}
						</AntdLayout.Header>
					)}

					<AntdLayout.Content>
						<Page>
							{!!props.renderTopPage && props.renderTopPage()}

							<Outlet />
						</Page>
					</AntdLayout.Content>
				</AntdLayout>

				<EnvHeadband />

				<ModalProvider />
			</AntdLayout>

			<DevToolbar />
		</View>
	);
}
