import React from 'react';

export default function useDidMount(didMount: () => (any | (() => void))) {
	React.useEffect(() => {
		const result = didMount();

		if (typeof result === 'function') { // willUnmount
			return result;
		}
	}, []);
}
