import React         from 'react';
import useTheme      from '@widesk/hooks/useTheme';
import View          from '@widesk/components/View';
import { TextProps } from '@widesk/components/Text';
import { ViewProps } from '@widesk/components/View/viewTypes';

type TitleProps = TextProps & ViewProps & {
	children: React.ReactNode;
	level?: 1 | 2 | 3 | 4 | 5;
}

const Title = (props: TitleProps) => {
	const theme = useTheme();

	const level = props.level || 1;

	const sizes = {
		1: theme.fontSizeHeading1,
		2: theme.fontSizeHeading2,
		3: theme.fontSizeHeading3,
		4: theme.fontSizeHeading4,
		5: theme.fontSizeHeading5,
	};

	const bolds = {
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
	};

	return (
		<View row inline bold={bolds[level]} size={sizes[level]} {...props} />
	);
};

export default Title;
