import { observer }     from 'mobx-react';
import { Modal }        from '@widesk/components/modal/Modal';
import globalModalStore from '@widesk/stores/globalModalStore';
import AntdModal        from 'antd/lib/modal';
import React            from 'react';

function ModalProviderComponent() {
	const [modal, contextHolder] = AntdModal.useModal();

	React.useEffect(() => {
		globalModalStore.setModalCtx(modal);
	}, []);

	return (
		<>
			{contextHolder}

			{globalModalStore.modals.map((_, idx) => {
				const modalProps = globalModalStore.modalsProps[idx]!;
				return <Modal key={idx} {...modalProps} />;
			})}
		</>
	);
}

export const ModalProvider = observer(ModalProviderComponent);
