import AntdAlert from 'antd/lib/alert';
import React     from 'react';

export type AlertProps = {
	message?: React.ReactNode;
	description?: React.ReactNode;
	banner?: boolean;
	showIcon?: boolean;
	type?: 'info' | 'warning' | 'error' | 'success';
}

const Alert = (props: AlertProps) => {
	return (
		<AntdAlert
			description={props.description}
			message={props.message}
			banner={props.banner}
			showIcon={props.showIcon}
			type={props.type}
		/>
	);
};

export default Alert;
