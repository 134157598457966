export default function (
	value = 3600 * 24 * 7, // En secondes
) { // Une semaine de cache par défaut
	return (target: any) => {
		Object.defineProperty(target, 'cacheDuration', {
			configurable: false,
			enumerable: false,
			value,
			writable: false,
		});
	};
}
