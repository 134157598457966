import { IResolvableDecoratorOptions } from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { ResolvableModelClass }        from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { resolvable as mResolvable }   from '@mathquis/modelx-resolvables';
import { AbstractResolvableModel }     from '@mathquis/modelx-resolvables';

export default function resolvable(
	modelClass: ResolvableModelClass<AbstractResolvableModel>,
	options?: WithRequiredProperty<IResolvableDecoratorOptions, 'attributeName'>,
): (
	target: AbstractResolvableModel,
	key: string,
) => void {
	return (target: AbstractResolvableModel, key: string) => {
		mResolvable(modelClass, { attributeName: key, ...options })(target, key);
	};
}
