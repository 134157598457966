import React        from 'react';
import authStore    from '@widesk/stores/authStore';
import { observer } from 'mobx-react';
import { message }  from '@widesk/hooks/useMessage';

type AuthContextType = {
	login: (username: string, password: string) => void;
	logout: () => void;
	token: string | null;
	isLogging?: boolean;
}

export const AuthContext = React.createContext<AuthContextType>({
	login: () => null,
	logout: () => null,
	isLogging: true,
	token: null,
});

function Provider(props: {
	children: React.ReactNode;
	onLogin: (username: string, password: string) => Promise<[string, string]>; // Promesse qui retourne le token et le refreshToken
	onLogout?: () => Promise<void>;
	onLogged?: (token: string) => Promise<void>; // Se déclenche lorsque l'utilisateur se connecte (ou est déjà connecté)
}) {
	const [isLogging, setIsLogging] = React.useState(true);

	React.useEffect(() => {
		(async () => {
			try {
				if (props.onLogged && authStore.token) {
					await props.onLogged(authStore.token);
				}
			} catch (err) {
				message.exception(err);

				throw err;
			} finally {
				setIsLogging(false);
			}
		})();
	}, []);

	const login = async (username: string, password: string) => {
		try {
			setIsLogging(true);

			const [newToken, newRefreshToken] = await props.onLogin(username, password);

			authStore.setToken(newToken);
			authStore.setRefreshToken(newRefreshToken);

			if (props.onLogged) {
				await props.onLogged(newToken);
			}
		} finally {
			setIsLogging(false);
		}
	};

	const logout = async () => {
		if (props.onLogout) {
			await props.onLogout();
		}

		authStore.clear();
	};

	return (
		<AuthContext.Provider value={{ login, logout, token: authStore.token, isLogging }}>
			{props.children}
		</AuthContext.Provider>
	);
}

const AuthProvider = observer(Provider);

export default AuthProvider;
