import AntdTooltip from 'antd/lib/tooltip';
import React       from 'react';
import useDidMount from '@widesk/hooks/useDidMount';

type TooltipRef = { close: () => void; };

const tooltipRefs: React.MutableRefObject<TooltipRef | undefined>[] = [];

export type TooltipProps = {
	children: React.ReactNode;
	title: React.ReactNode;
	placement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop'
		| 'leftBottom' | 'rightTop' | 'rightBottom';
}

const Tooltip = (props: TooltipProps) => {
	const [open, setOpen] = React.useState(false);

	const ref = React.useRef<TooltipRef>();

	React.useImperativeHandle(ref, () => ({ close: () => setOpen(false) }));

	useDidMount(() => {
		tooltipRefs.push(ref);
		return () => tooltipRefs.splice(tooltipRefs.indexOf(ref), 1);
	});

	return (
		<AntdTooltip
			open={open}
			destroyTooltipOnHide
			title={props.title}
			placement={props.placement}
			getPopupContainer={() => document.getElementById('root')!} // Permet aux dropdowns du wysiwyg de s'afficher
			onOpenChange={v => setOpen(v)}
		>
			{props.children}
		</AntdTooltip>
	);
};

Tooltip.closeAll = () => tooltipRefs.forEach(ref => ref.current?.close());

export default Tooltip;
