import React                 from 'react';
import { MouseEventHandler } from 'react';
import AntdButton            from 'antd/lib/button';
import { ButtonType }        from 'antd/lib/button/buttonHelpers';
import { ButtonHTMLType }    from 'antd/lib/button/buttonHelpers';
import Tooltip               from '@widesk/components/Tooltip';

export type ButtonProps = {
	block?: boolean;
	children: React.ReactNode;
	style?: React.CSSProperties;
	type?: ButtonType;
	htmlType?: ButtonHTMLType;
	onClick?: MouseEventHandler;
	disabled?: boolean;
	icon?: React.ReactNode;
	size?: ComponentSize;
	title?: React.ReactNode; // Texte affiché dans le tooltip du bouton
	danger?: boolean;
	loading?: boolean;
}

const Button = (props: ButtonProps) => {
	return (
		<Tooltip title={props.title}>
			<AntdButton
				style={props.style}
				type={props.type}
				block={props.block}
				htmlType={props.htmlType}
				onClick={props.onClick}
				disabled={props.loading || props.disabled}
				icon={props.icon}
				loading={props.loading}
				danger={props.danger}
				size={props.size}
			>
				{props.children}
			</AntdButton>
		</Tooltip>
	);
};

export default Button;
