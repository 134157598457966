import '@widesk/components/styles/Modal.scss';

import AntdModal        from 'antd/lib/modal';
import ModalContext     from '@widesk/contexts/ModalContext';
import ModalStore       from '@widesk/stores/modalStore';
import React            from 'react';
import globalModalStore from '@widesk/stores/globalModalStore';
import useTheme         from '@widesk/hooks/useTheme';
import useWillMount     from '@widesk/hooks/useWillMount';
import { observer }     from 'mobx-react';
import Tooltip          from '@widesk/components/Tooltip';

import type { DraggableEvent } from 'react-draggable';
import type { DraggableData }  from 'react-draggable';
import Draggable               from 'react-draggable';

export type ModalProps = React.PropsWithChildren & {
	open?: boolean;
	maskClosable?: boolean;
	onClose?: () => void;
	title?: React.ReactNode;
	footer?: React.ReactNode | null;
	width?: string | number;
	isDraggable?: boolean;
	fullScreen?: boolean;
	bodyPadding?: number;
	okButtonProps?: Record<string, any>;
	modalStore?: ModalStore;
	keyboard?: boolean; // Support ou non de la touche esc (default: true)
	closable?: boolean; // Affichage du "x" en haut à droite de la modal (default: true)
}

export function Modal(props: ModalProps) {
	return <ModalComponent {...props} />;
}

const ModalComponent = observer((props: ModalProps) => {
	const modalStore = useWillMount(() => props.modalStore || new ModalStore({
		...props,
	}));

	const { isOpen, isSubmitting, isDisabled } = modalStore;

	const theme = useTheme();

	const mergedProps = { ...props, ...modalStore.additionalProps };

	const [disabled, setDisabled] = React.useState<boolean>(!props.isDraggable || true);
	const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 });

	const draggableRef = React.useRef<HTMLDivElement>(null);

	const isDraggable = !!props.title && !props.fullScreen;

	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggableRef.current?.getBoundingClientRect();

		if (!targetRect) return;

		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	};

	const classNames = ['widesk-modal'];

	if (props.fullScreen) classNames.push('fullscreen');

	return (
		<ModalContext.Provider value={{ modalStore }}>
			<AntdModal
				bodyStyle={{
					paddingTop: theme.marginMD,
					paddingBottom: theme.marginMD,
					padding: props.bodyPadding,
					borderTop: props.title ? `1px solid ${theme.colorBorder}` : undefined,
					borderBottom: mergedProps.footer !== null ? `1px solid ${theme.colorBorder}` : undefined, // Pas de séparateur si pas de footer
				}}
				className={classNames.join(' ')}
				getContainer={document.getElementById('root')!} // Permet aux dropdowns du wysiwyg de s'afficher
				open={isOpen}
				closeIcon={isSubmitting ? false : undefined}
				confirmLoading={isSubmitting}
				okButtonProps={{
					...props.okButtonProps,
					disabled: isSubmitting || isDisabled,
					size: 'large',
				}}
				cancelButtonProps={{ disabled: isSubmitting, size: 'large' }}
				maskClosable={typeof props.maskClosable === 'undefined' ? false : props.maskClosable}
				onCancel={() => modalStore.setIsOpen(false)}
				afterOpenChange={value => {
					if (!value) {
						globalModalStore.removeLastModal();

						if (props.onClose) {
							props.onClose();
						}
					} else {
						Tooltip.closeAll(); // Quand on ouvre une modale, on ferme tous les tooltips (hack pour éviter que les tooltips restent apparents)
					}
				}}
				onOk={async () => modalStore.submit()}
				title={props.title && isDraggable ? (
					<div
						style={{ width: '100%', cursor: 'move' }}
						onMouseOver={() => disabled && setDisabled(false)}
						onMouseOut={() => setDisabled(true)}
					>
						{props.title}
					</div>
				) : props.title}
				footer={props.footer}
				width={props.width || 750}
				keyboard={props.keyboard}
				closable={props.closable}
				modalRender={isDraggable ? modal => (
					<Draggable
						disabled={disabled}
						bounds={bounds}
						nodeRef={draggableRef}
						onStart={(event, uiData) => onStart(event, uiData)}
					>
						<div ref={draggableRef}>{modal}</div>
					</Draggable>
				) : undefined}

				{...modalStore.additionalProps}
			>
				{props.children}
			</AntdModal>
		</ModalContext.Provider>
	);
});

export type ModalMethodProps = {
	title?: React.ReactNode;
	onOk?: () => void;
	content?: React.ReactNode;
	okType?: 'danger';
	okText?: React.ReactNode;
	cancelText?: React.ReactNode;
	width?: number;
	cancelType?: 'danger';
}

Modal.open = globalModalStore.addModal;

Modal.close = globalModalStore.removeLastModal;

Modal.confirm = (props: ModalMethodProps) => globalModalStore.useModal()?.confirm({
	cancelText: props.cancelText || `Annuler`,
	maskClosable: true,
	okText: props.okText || `Continuer`,
	width: props.width || 450,
	cancelButtonProps: props.cancelType ? { danger: true } : undefined,

	...props,
});

Modal.confirmAsync = (props: Omit<ModalMethodProps, 'onOk' | 'onCancel'>): Promise<boolean> => {
	return new Promise(resolve => {
		globalModalStore.useModal()?.confirm({
			cancelText: props.cancelText || `Annuler`,
			maskClosable: true,
			okText: props.okText || `Continuer`,
			width: props.width || 450,
			cancelButtonProps: props.cancelType ? { danger: true } : undefined,
			onOk: () => resolve(true),
			onCancel: () => resolve(false),

			...props,
		});
	});
};
