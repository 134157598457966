import translationStore          from '@widesk/stores/translationStore';
import modelsFr                  from '@/models/localization/fr.json';
import { setModelLocalizedData } from '@widesk/mixins/ApiModelLocalizedLabel';
import { setLocale }             from '@widesk/locale';

setLocale('fr');

setModelLocalizedData(modelsFr);

const translations = {
	fr: { 'title': 'Français', 'image': '/flags/fr.png' },
	en: { 'title': 'Anglais', 'image': '/flags/en.png' },
};

translationStore.setTranslations(translations);
