import { IResolvableOptions } from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { ModelClass }         from '@mathquis/modelx/lib/types/collection';
import resolvableUrn          from '@widesk/decorators/resolvableUrn';
import ApiModel               from '@widesk/models/ApiModel';

export interface IBlamedModel extends ApiModel {
	email: string,
	fullName: string;
	urn: Urn
}

export class BlamableEmptyModel extends ApiModel implements IBlamedModel {
	public constructor(props: any) {
		super(props);

		this.setIsLoaded(true);
	}

	public get email(): string {
		return '<unknown>';
	}

	public get fullName(): string {
		return this.id ? '<unknown>' : 'le système';
	}

	public get urn(): Urn {
		return '' as Urn; // TODO CLEAN REMOVE
	}
}

export default function Blamable<T extends ModelClass<ApiModel>>(Base: T, options?: IResolvableOptions) {
	const c = class Blamable extends Base {
		declare createdBy: IBlamedModel;
		declare updatedBy: IBlamedModel;

		constructor(...args: any[]) {
			super(...args);
		}
	};

	resolvableUrn({
		attributeName: '',
		cache: true,
		unresolved: BlamableEmptyModel,
		...options,
	})(c.prototype, 'createdBy');

	resolvableUrn({
		attributeName: '',
		cache: true,
		unresolved: BlamableEmptyModel,
		...options,
	})(c.prototype, 'updatedBy');

	return c;
}
