import './styles/Box.scss';

import React         from 'react';
import AntdCard      from 'antd/lib/card';
import BoxContext    from '@widesk/contexts/BoxContext';
import View          from '@widesk/components/View';
import useDashboard  from '@widesk/hooks/useDashboard';
import BoxStore      from '@widesk/stores/BoxStore';
import useTheme      from '@widesk/hooks/useTheme';
import { Action }    from '@widesk/components/ButtonActions';
import ButtonActions from '@widesk/components/ButtonActions';
import { observer }  from 'mobx-react';
import useDidMount   from '@widesk/hooks/useDidMount';

export type BoxProps = {
	id?: string;
	className?: string;
	title?: React.ReactNode;
	cover?: React.ReactNode;
	children?: React.ReactNode;
	containerStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	inner?: boolean;
	bodyStyle?: React.CSSProperties;
	headStyle?: React.CSSProperties;
	renderTopRight?: () => React.ReactNode;
	dashboardTitle?: string; // permet de saisir un titre précis pour le header du dashboard
	dashboardDisabled?: boolean; // permet de masquer l'ancre de la box dans le header du dashboard
	fullHeight?: boolean; // la box prend la hauteur de son conteneur (défaut "true" si on est dans un dashboard)
	actions?: Action[];
}

const Box = observer((props: BoxProps) => {
	const [boxStore] = React.useState(() => new BoxStore(props));

	const isDisplayedExtra = !!props.renderTopRight || !!boxStore.ctxTopRight || !!props.actions;

	const theme = useTheme();
	const { dashboardStore } = useDashboard();

	boxStore.setProps(props);

	useDidMount(() => dashboardStore?.pushBoxStore(boxStore));

	const fullHeight = typeof props.fullHeight === 'undefined' ? !!dashboardStore : props.fullHeight;

	return (
		<View
			className={[
				'widesk-box',
				props.className || '',
				fullHeight ? 'full-height' : '',
			]}
			id={boxStore.id}
			style={{ borderRadius: theme.borderRadius, ...props.containerStyle }}
		>
			<BoxContext.Provider value={{ boxStore }}>
				<AntdCard
					type={props.inner ? 'inner' : undefined}
					style={props.style}
					cover={props.cover}
					title={props.title}
					headStyle={{ color: theme.colorTextDescription, ...props.headStyle }}
					bodyStyle={props.bodyStyle}
					extra={isDisplayedExtra && (
						<View row gap={10} centerV>
							{!!props.renderTopRight && props.renderTopRight()}
							{!!boxStore.ctxTopRight && boxStore.ctxTopRight()}
							{!!props.actions && <ButtonActions actions={props.actions} />}
						</View>
					)}
				>
					{props.children}
				</AntdCard>
			</BoxContext.Provider>
		</View>
	);
});

export default Box;
