import ApiConnector from '@/models/ApiConnector';
import ServiceModel from '@models/ServiceModel';

const service = window.APP_CONFIG.services.find(service => service.id === 'admin');

export const adminConnector = new ApiConnector({ baseURL: service?.configurations.api_endpoint });

export default class AdminServiceModel extends ServiceModel {
	public static get connector() {
		return adminConnector;
	}

	public static serviceName = 'admin';
	public static partitionName = '$';
}
