import { observable } from 'mobx';
import { action }     from 'mobx';
import { ModalProps } from '@widesk/components/modal/Modal';
import _uniqueId      from 'lodash/uniqueId';
import { HookAPI }    from 'antd/lib/modal/useModal';
import devToolbarStore from '@widesk/stores/devToolbarStore';

// On sépare modal et modalProps pour éviter que les props soient wrapped dans le proxy mobx
const modals = observable<string>([]);
const modalsProps: ModalProps[] = [];

let modalCtx: HookAPI | undefined = undefined;

export default {
	modals,
	modalsProps,
	addModal: action((modalProps: ModalProps) => {
		devToolbarStore.clearRequests();
		modals.push(_uniqueId());
		modalsProps.push({ open: true, ...modalProps });
	}),
	removeLastModal: action(() => {
		modals.pop();
		modalsProps.pop();
	}),
	setModalCtx: (modal: HookAPI) => modalCtx = modal,
	useModal: () => modalCtx,
};
