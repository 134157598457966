const defaultUrnRegex = /[a-z$]+[:.]([a-z]+)[:.]([a-z_]+)[:.](\d+)/;

export const splitUrn = (urn: Urn): string[] => {
	const regex = new RegExp(defaultUrnRegex);
	const matches = urn.match(regex);

	if (!matches || matches.length !== 4) {
		console.error(`Erreur : "${urn}" n'est pas un format d'URN valide.`);

		return [];
	}

	return matches;
};

export function isUrn(string: string): boolean {
	return defaultUrnRegex.test(string);
}

export function getIdFromUrn(urn: Urn): id {
	return splitUrn(urn)[3] || '';
}
