import { ModalProps }     from '@widesk/components/modal/Modal';
import { makeObservable } from 'mobx';
import { observable }     from 'mobx';
import { action }         from 'mobx';
import { computed }       from 'mobx';

type ModalCanHaveFormProps = ModalProps & { formId?: string };

export default class ModalStore {
	@observable private _additionalProps: Partial<ModalCanHaveFormProps> = {};
	@observable private _isDisabled = false;
	@observable private _isOpen = false;
	@observable private _isSubmitting = false;
	@observable private _submitCallback = () => (null as unknown);

	public readonly props: ModalCanHaveFormProps;

	public constructor(props: ModalCanHaveFormProps) {
		this.setIsOpen(!!props.open);

		this.props = props;

		makeObservable(this);
	}

	@action setAdditionalProps(value: Partial<ModalCanHaveFormProps>) {
		this._additionalProps = value;
	}

	@action setSubmitCallback(callback: () => unknown) {
		this._submitCallback = callback;
	}

	@action setIsDisabled(value: boolean) {
		this._isDisabled = value;
	}

	@action setIsOpen(value: boolean) {
		this._isOpen = value;
	}

	@action setIsSubmitting(value: boolean) {
		this._isSubmitting = value;
	}

	@computed get isSubmitting() {
		return this._isSubmitting;
	}

	@computed get isOpen() {
		return this._isOpen;
	}

	@computed get isDisabled() {
		return this._isDisabled;
	}

	@computed get additionalProps() {
		return this._additionalProps;
	}

	public submit() {
		return this._submitCallback();
	}
}
