type ENV = 'local' | 'dev' | 'demo' | 'preprod' | 'prod';

const API_ENV = import.meta.env.VITE_API_ENV as ENV || ((): ENV => {
    if (location.hostname.startsWith('dev-')) return 'dev';
    else if (location.hostname.startsWith('demo-')) return 'demo';
    else if (location.hostname.startsWith('preproduction-')) return 'preprod';

    return 'prod';
})();

const apiEndpoints: Record<ENV, string> = {
    local: 'https://tokamap-api.docker.localhost',
    dev: 'https://dev-tokamap-api.staging.stonecode.io',
    demo: 'https://demo-tokamap-api.staging.stonecode.io',
    preprod: 'https://preproduction-tokamap-api.staging.stonecode.io',
    prod: 'https://api.tokamap.com',
};

const studioUrls: Record<ENV, string> = {
	local: 'http://localhost:7064',
	dev: 'https://dev-studio-tokamap-com.staging.stonecode.io',
	demo: 'https://demo-studio-tokamap-com.staging.stonecode.io',
	preprod: 'https://preproduction-studio-tokamap-com.staging.stonecode.io',
	prod: 'https://studio.tokamap.com',
};

const baseApiEndpoint = apiEndpoints[API_ENV];
const studioUrl = __LOCAL__ ? studioUrls.local : studioUrls[API_ENV];

window.FRONT_CONFIG = {
    devToolbarEnabled: API_ENV === 'dev' || API_ENV === 'local',
    env: API_ENV,
    displayEnvApi: true
};

window.APP_CONFIG = {
    services: [
        {
            id: 'admin',
            configurations: {
                api_endpoint: `${baseApiEndpoint}/admin`,
            },
        },
        {
            id: 'account',
            configurations: {
                api_endpoint: `${baseApiEndpoint}/account`,
            },
        },
        {
            id: 'map',
            configurations: {
                api_endpoint: `${baseApiEndpoint}/map`,
            },
        },
    ],
};

window.STUDIO_URL = studioUrl;