import antdMessage                  from 'antd/lib/message';
import { MessageInstance }          from 'antd/lib/message/interface';
import React                        from 'react';
import getTextAndTranslateFromError from '@widesk/tools/getTextAndTranslateFromError';
import { ArgsProps }                from 'antd/lib/message/interface';

let _message: MessageInstance;

export const open = (config: ArgsProps) => getMessage()?.open(config);
export const info = (content: React.ReactNode, duration = 4) => getMessage()?.info(content, duration);
export const success = (content: React.ReactNode, duration = 4) => getMessage()?.success(content, duration);
export const error = (content: React.ReactNode, duration = 4) => getMessage()?.error(content, duration);
export const exception = (error: any, duration = 4) => {
	console.error(error);
	return getMessage()?.error(getTextAndTranslateFromError(error), duration);
};
export const warning = (content: React.ReactNode, duration = 4) => getMessage()?.warning(content, duration);

export default function useMessage() {
	const [messageApi, contextHolder] = antdMessage.useMessage({ top: 60 });

	_message = messageApi;

	return [contextHolder];
}

const getMessage = () => {
	if (_message) return _message;
	console.error(`L'instance de message n'a pas encore été initialisée.`);
};

export const message = { success, error, exception, warning, info, open };
