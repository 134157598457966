type Translations = Partial<Record<LanguageKey, { title: string; image: string; }>>;

class TranslationStore {
	private _translations: Translations = {};

	public setTranslations(translations: Translations) {
		this._translations = translations;
	}

	public get(key: LanguageKey) {
		return this._translations[key];
	}

	public get translations() {
		return this._translations;
	}

	public get translationKeys() {
		return Object.keys(this._translations) as LanguageKey[];
	}
}

export default new TranslationStore();
