import { ModelClass } from '@mathquis/modelx/lib/types/collection';
import { computed }   from 'mobx';
import ApiModel       from '@widesk/models/ApiModel';
import dayjs          from 'dayjs';

export default function TimestampAble<T extends ModelClass<ApiModel>>(Base: T) {
	const c = class TimestampAble extends Base {
		public get createdAt() {
			return dayjs(this.get('createdAt', dayjs()));
		}

		public get updatedAt() {
			return dayjs(this.get('updatedAt', dayjs()));
		}

		public get createdAtFormatted(): string {
			return this.createdAt.format('L à LT');
		}

		public get updatedAtFormatted(): string {
			return this.updatedAt.format('L à LT');
		}

		constructor(...args: any[]) {
			super(...args);
		}
	};

	computed(c, 'createdAt');
	computed(c, 'updatedAt');

	return c;
}
