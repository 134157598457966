import { LinkProps, Link as ReactRouterLink }                           from 'react-router-dom';
import useList                                                          from '@widesk/hooks/useList';

const Link = (props: LinkProps) => {
	const { listStore } = useList();

	// On regarde si le lien appartient à une liste de SplitView
	const isInSplitView = !!listStore?.splitViewStore;

	return (
		<ReactRouterLink
			onClick={isInSplitView ? e => e.preventDefault() : undefined}
			target={props.target}
			{...props}
		/>
	);
};

export default Link;
